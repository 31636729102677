import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Service from "../../services/bodyParams";

type Props = {
  updateData: () => void; // Funkcja do odświeżania danych
};

const PostBirthDate: React.FC<Props> = ({ updateData }) => {
  const { t } = useTranslation("global");

  const [year, setYear] = useState<number | "">("");
  const [month, setMonth] = useState<number | "">("");
  const [day, setDay] = useState<number | "">("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (year && month && day) {
      const birthdate = new Date(year, month - 1, day); // Miesiące w JS zaczynają się od 0
      Service.postBirthDate(birthdate)
        .then(() => {
          console.log("Wysłano datę:", birthdate);
          updateData(); // Odśwież dane po pomyślnym wysłaniu
        })
        .catch((error) => {
          console.error("Błąd podczas wysyłania daty:", error);
        });
    } else {
      console.log("Wybierz pełną datę urodzenia.");
    }
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let y = currentYear; y >= 1900; y--) {
      years.push(y);
    }
    return years;
  };

  const generateMonths = () => {
    return Array.from({ length: 12 }, (_, i) => i + 1); // [1, 2, ..., 12]
  };

  const generateDays = () => {
    if (year && month) {
      const daysInMonth = new Date(year, month, 0).getDate(); // Ostatni dzień danego miesiąca
      return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    }
    return [];
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          {t("buttons.select_year")}:
          <select value={year} onChange={(e) => setYear(Number(e.target.value) || "")}>
            <option value="">{t("buttons.select")}</option>
            {generateYears().map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          {t("buttons.select_month")}:
          <select value={month} onChange={(e) => setMonth(Number(e.target.value) || "")}>
            <option value="">{t("buttons.select")}</option>
            {generateMonths().map((m) => (
              <option key={m} value={m}>
                {t(`months.${m}`)}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          {t("buttons.select_day")}:
          <select value={day} onChange={(e) => setDay(Number(e.target.value) || "")}>
            <option value="">{t("buttons.select")}</option>
            {generateDays().map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
        </label>
        <br />
        <button type="submit">{t("buttons.add")}</button>
      </form>
    </div>
  );
};

export default PostBirthDate;
