import React, { Component } from "react";
import Service from '../../../../../services/exercises'
import OptionsDelete from "./OptionsDelete";
import { withTranslation } from "react-i18next";



type Props = {
    exerciseId: string;
    trainingId: number;
    t: any;
    onExerciseOptionDelete: (exerciseId: string) => void;
};
type State = {};

const handleExerciseOptionDelete = (updatedExercises: any[]) => {
    console.log('Updated exercises:', updatedExercises);
};

class Options extends Component<Props, State> {
    render() {
        const { exerciseId, trainingId, onExerciseOptionDelete, t } = this.props;

        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                <OptionsDelete
                    exerciseId={exerciseId}
                    trainingId={trainingId}
                    onExerciseOptionDelete={onExerciseOptionDelete}
                />
                <label htmlFor={t("buttons.move_up")} className="icon-option">
                    <img src="/images/navigation/move_up.svg"  />
                </label>
                <label htmlFor={t("buttons.move_down")} className="icon-option">
                    <img src="/images/navigation/move_down.svg"  />
                </label>
            </div>
        );
    }
}

export default withTranslation("global")(Options);
