import axios from 'axios';
import authHeader from '../../auth-header';
import { NewDictExercises, NewDictQuantityType, NewExercise, NewDictUnit, NewDictCategory, CopyParametersDto } from '../../../types/types'
import { API_URL } from '../../../constants/api'

class ExercisesTrainingService {
  
  deleteExercise(trainingId: number, exerciseId: string) {
    return axios.delete(`${API_URL}/delete_exercise_training/${trainingId}/${exerciseId}`, { headers: authHeader() });
  }
}

export default new ExercisesTrainingService();