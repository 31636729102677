import React, { Component } from "react";
import ExercisesTrainingService from '../../../../../services/exercises/training/ExerciseTrainingService';
import { withTranslation } from "react-i18next";

type Props = {
  exerciseId: string;
  trainingId: number;
  onExerciseOptionDelete: (exerciseId: string) => void;
  t: any;
};
type State = {};

class OptionsDelete extends Component<Props, State> {
    handleDelete = async () => {
        const { exerciseId, trainingId, onExerciseOptionDelete, t } = this.props; 
        const confirmMessage = `${t('buttons.delete_session_row')}`;

        if (window.confirm(confirmMessage)) {
            try {
                await ExercisesTrainingService.deleteExercise(trainingId, exerciseId);
                onExerciseOptionDelete(exerciseId); // Przekazanie exerciseId do rodzica
            } catch (error) {
                console.error('Error deleting exercise:', error);
            }
        }
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <label htmlFor={t("buttons.delete")} className="icon-option">
                    <img src="/images/navigation/bin.svg" onClick={this.handleDelete} alt={t("buttons.delete")} />
                </label>
            </div>
        );
    }
}

export default withTranslation("global")(OptionsDelete);

