import React, { Component } from 'react';
import { Workbook } from '../../../../types/types';
import PostTraining from './postTraining';
import ShowTrainings from './showTrainings';

type Props = {
  workbook: Workbook
}

type State = {
  refreshKey: number;
};

class DisplaySection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      refreshKey: 0, // Klucz do wymuszania odświeżenia
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    // Aktualizujemy klucz odświeżania
    this.setState((prevState) => ({
      refreshKey: prevState.refreshKey + 1,
    }));
  }

  render() {
    const { workbook } = this.props;
    const { refreshKey } = this.state;

    return (
      <div>
         {/* Przekazujemy funkcję odświeżania do PostTraining */}
         <PostTraining workbook_id={workbook.id} onRefresh={this.handleRefresh} />
        {/* Przekazujemy klucz odświeżania do ShowTrainings */}
        <ShowTrainings workbook_id={workbook.id} key={refreshKey} />
      </div>
    );
  }
}

export default DisplaySection;
