import React, { Component } from 'react';
import AddExercise from './addExercise';
import GetExercise from './getExercise';

type Props = {
    workbook_id: number,
    training_id: number
}

type State = {
    refreshKey: number; // Used to trigger a re-render in GetExercise
};

class Exercise extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            refreshKey: 0,
        };
    }

    handleRefresh = () => {
        this.setState((prevState) => ({
            refreshKey: prevState.refreshKey + 1,
        }));
    };

    render() {
        const { workbook_id, training_id } = this.props;
        const { refreshKey } = this.state;

        return (
            <div>
                <AddExercise workbook_id={workbook_id} training_id={training_id} onRefresh={this.handleRefresh} />
                <GetExercise training_id={training_id} key={refreshKey} />
            </div>
        );
    }
}

export default Exercise;
